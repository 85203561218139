/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ContentfulRichText from '../components/ContentfulRichText';
import Layout from '../components/layout';
import Container from '../components/Container';
import SEO from '../components/seo';
import {
  Hero,
  HeroHeading,
  SectionTitle,
  BodyText,
  ContentWrap
} from '../components/Global';
import SignUpModal from '../components/SignUpModal';
import MediaBreakDown from '../styles/MediaBreakDown';


const HeroWrap = styled(Hero)`
  .gatsby-image-wrapper {
    width: 105px;
    height: auto;
    margin: auto;
    margin-bottom: 33px;
  }
  ${MediaBreakDown.md`
    .gatsby-image-wrapper {
      width: 80px;
      height: 80px;
    }
  `}
`;

const LastSection = styled.div`
  margin: 0 auto 160px;
  width: 100%;
  max-width: 760px;
  text-align: center;

  p {
    max-width: 450px;
    margin: 12px auto 40px;
  }
`;

const ExtensionPage = ({ location, data }) => {
  const { contentfulExtensionPage: extension } = data;
  const seoMeta = {
    title: extension.seoTitle || `${extension.name} | Extension | Workast`,
    description: extension.seoDescription
      ? extension.seoDescription.seoDescription : extension.shortDescription.shortDescription
  };
  return (
    <Layout location={location}>
      <SEO
        title={seoMeta.title}
        description={seoMeta.description}
      />
      <Container>
        <HeroWrap>
          <GatsbyImage image={getImage(extension.icon)} />
          <HeroHeading mb="21px">{extension.title}</HeroHeading>
          <BodyText mb="35px">{extension.shortDescription.shortDescription}</BodyText>
          <SignUpModal buttonText="Get Started" />
        </HeroWrap>
        { extension.content ? (
          <ContentWrap>
            <ContentfulRichText richText={extension.content} />
          </ContentWrap>
        ) : (
          <></>
        )}
        <LastSection>
          <SectionTitle>
            Make teamwork a breeze
          </SectionTitle>
          <BodyText>
            Install Workast to your Slack workspace and start organizing your teamwork today.
          </BodyText>
          <SignUpModal buttonText="Get Started" />
        </LastSection>
      </Container>
    </Layout>
  );
};

ExtensionPage.propTypes = {
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    contentfulExtensionPage: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string,
      shortDescription: PropTypes.shape({
        shortDescription: PropTypes.string
      }).isRequired,
      seoTitle: PropTypes.string,
      seoDescription: PropTypes.shape({
        seoDescription: PropTypes.string
      }),
      // eslint-disable-next-line react/forbid-prop-types
      icon: PropTypes.object,
      // eslint-disable-next-line react/forbid-prop-types
      content: PropTypes.object,
    }).isRequired,
  }).isRequired,
};

export default ExtensionPage;

export const pageQuery = graphql`
  query ExtensionPageQuery($slug: String! ){
    contentfulExtensionPage(slug: { eq: $slug }) {
      name
      title
      slug
      shortDescription {
        shortDescription
      }
      seoTitle
      seoDescription {
        seoDescription
      }
      icon {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData
          }
        }
      }
    }
  }
`;
